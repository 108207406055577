import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../components/SplashScreen";

const AutoavaliacaoPage = lazy(() => import("./autoavaliacao/AutoavaliacaoPage"));

const InicioPage = lazy(() => import("./inicio/InicioPage"));

const TurmaPage = lazy(() => import("./turma/TurmaPage"));

const AvaliacaoPage = lazy(() => import("./avaliacao/AvaliacaoPage"));

const AvaliacaoIniciar = lazy(() => import("./avaliacao/AvaliacaoIniciar"));

const AvaliacaoResponder = lazy(() => import("./avaliacao/AvaliacaoResponder"));

const AvaliacaoResultado = lazy(() => import("./avaliacao/AvaliacaoResultado"));

const AvaliacaoRevisao = lazy(() => import("./avaliacao/AvaliacaoRevisao"));

const AtividadePage = lazy(() => import("./atividade/AtividadePage"));

const AtividadeIniciar = lazy(() => import("./atividade/AtividadeIniciar"));

const AtividadeResponder = lazy(() => import("./atividade/AtividadeResponder"));

const PerfilPage = lazy(() => import("./perfil/PerfilPage"));

const QuadroNotaPage = lazy(() => import("./quadro-nota/QuadroNotaPage"));

const NotaPage = lazy(() => import("./quadro-nota/NotaPage"));

const TurmasAcessoPage = lazy(() => import("./turma/TurmasAcessoPage"));

export default function HomePage() {
    const { user } = useSelector((state) => state.auth);

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {!user.autoavaliacao && (
                    <>
                        <Route path="/autoavaliacao" component={AutoavaliacaoPage} />
                        <Redirect to="/autoavaliacao" />
                    </>
                )}

                <Redirect exact from="/" to="/inicio" />
                <Redirect from="/auth" to="/inicio" />

                <Route path="/inicio" component={InicioPage} />

                <Route path="/perfil" component={PerfilPage} />

                <Route path="/turma/:id/avaliacao" component={AvaliacaoPage} />

                <Route path="/turma/:id/atividade" component={AtividadePage} />

                <Route path="/turma/:id" component={TurmaPage} />

                <Route path="/avaliacao/:id/revisao" component={AvaliacaoRevisao} />

                <Route path="/avaliacao/:id/resultado" component={AvaliacaoResultado} />

                <Route path="/avaliacao/:id/responder" component={AvaliacaoResponder} />

                <Route path="/atividade/:id/responder" component={AtividadeResponder} />

                <Route path="/avaliacao/:id" component={AvaliacaoIniciar} />

                <Route path="/atividade/:id" component={AtividadeIniciar} />

                <Route path="/quadro-nota/:id" component={NotaPage} />

                <Route path="/quadro-nota" component={QuadroNotaPage} />

                <Route path="/turma-acesso" component={TurmasAcessoPage} />

                <Redirect to="/" />
            </Switch>
        </Suspense>
    );
}
