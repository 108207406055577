import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../components/SplashScreen";

const LoginPage = lazy(() =>
	import("./LoginPage")
);

const CadastroPage = lazy(() =>
	import("./CadastroPage")
);

const EsqueceuSenhaPage = lazy(() =>
	import("./EsqueceuSenhaPage")
);

const AlterarSenhaPage = lazy(() =>
	import("./AlterarSenhaPage")
);

export default function AuthPage() {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				<Redirect exact from="/" to="/login" />

				<Route path="/login" component={LoginPage} />
				<Route path="/cadastro" component={CadastroPage} />
				<Route path="/esqueceu-senha" component={EsqueceuSenhaPage} />
				<Route path="/alterar-senha" component={AlterarSenhaPage} />

				<Redirect to="/" />
			</Switch>
		</Suspense>
	);
}