import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import { LayoutContextProvider } from "../components/SplashScreen";
import HomePage from "../pages/home/HomePage";
import LogoutPage from "../pages/auth/Logout";
import AuthPage from "../pages/auth/AuthPage";
import * as routerHelpers from "../router/RouterHelpers";

export const Routes = withRouter(({ history }) => {
	const lastLocation = useLastLocation();

	routerHelpers.saveLastLocation(lastLocation);

	const { isAuthorized, menuConfig, userLastLocation } = useSelector(
		({ auth }) => ({
			isAuthorized: auth.user != null,
			userLastLocation: routerHelpers.getLastLocation()
		}),
		shallowEqual
	);

	return (
		<LayoutContextProvider history={history} menuConfig={menuConfig}>
			<Switch>
				<Route path="/logout" component={LogoutPage} />

				{!isAuthorized ?
					<Route component={AuthPage} />
					:
					<HomePage userLastLocation={userLastLocation} />
				}
			</Switch>
		</LayoutContextProvider>
	);
});
